// Dashboard Route
export const tradeTariffDasboardRoute = '/configuration/dashboard'

// Master acid-name Routes......
const acidName = '/configuration/acid-name/'
export const acidNameList = acidName + 'list'
export const acidNameStore = acidName + 'store'
export const acidNameUpdate = acidName + 'update'
export const acidNameToggle = acidName + 'toggle-status'

// Master payment info Routes......
const paymentInfo = '/configuration/payment-info/'
export const paymentInfoList = paymentInfo + 'list'
export const paymentInfoStore = paymentInfo + 'store'
export const paymentInfoUpdate = paymentInfo + 'update'
export const paymentInfoToggle = paymentInfo + 'toggle-status'

// Report Heading
const reportHeading = '/configuration/report-heading/'
export const reportHeadingListApi = reportHeading + 'list'
export const reportHeadingStoreApi = reportHeading + 'store'
export const reportHeadingUpdateApi = reportHeading + 'update'
export const reportHeadingToggleApi = reportHeading + 'toggle-status'
